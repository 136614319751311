import { ZodCustomIssue, ZodIssue, ZodIssueCode } from 'zod'

type FieldHasAnyIssue = (path: string) => boolean

export type FieldHasIssue = (path: string, code: ZodIssueCode, message?: string) => ZodIssue | ZodCustomIssue | undefined

export function useZodValidation(issues: ZodIssue[] = []): {
  fieldHasAnyIssue: FieldHasAnyIssue
  fieldHasIssue: FieldHasIssue
} {
  return {
    fieldHasAnyIssue(path: string) {
      return !!issues.find((issue) => issue.path[0] === path)
    },
    fieldHasIssue(path: string, code: ZodIssueCode, message?: string) {
      return issues.find((issue) =>
        [issue.path[0] === path, issue.code === code, message ? issue.message === message : true].every((val) => !!val)
      )
    }
  }
}
