// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/form/TextInput.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/form/TextInput.tsx");
}
// REMIX HMR END

import clsx from 'clsx';
import styles from './TextInput.module.css';
export function TextInput({
  className,
  invalid,
  type = 'text',
  ...props
}) {
  return <input className={clsx(styles.input, invalid && styles.invalid, className)} type={type} {...props} />;
}
_c = TextInput;
var _c;
$RefreshReg$(_c, "TextInput");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;